import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { axiosInstance } from '../../utils/axiosConfig';
import {
  RevenueAndExpensesReponse,
  RevenueAndExpensesState
} from '../../@types/revenue';
import { APIStatus, APIError } from '../../@types/APIStatus';
import { OrderRevenueAndCostsFiltersState } from '../../@types/filterBars';

const initialState: RevenueAndExpensesState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceChannels: [],
    countries: [],
    customers: [],
    shopId: 0,
    profitBy: 0
  },
  APIStatus: APIStatus.IDLE,
  data: {
    xaxis: [],
    expenses: {
      tending: 0,
      total: 0,
      data: []
    },
    revenue: {
      tending: 0,
      total: 0,
      totalGross: 0,
      data: []
    },
    margin: {
      tending: 0,
      total: 0,
      data: []
    }
  }
};

const slice = createSlice({
  name: 'RevenueAndExpenses',
  initialState,
  reducers: {
    setRevenueAndExpenses(
      state: RevenueAndExpensesState,
      action: PayloadAction<RevenueAndExpensesReponse>
    ) {
      state.data = action.payload;
    },
    setFilters(
      state: RevenueAndExpensesState,
      action: PayloadAction<OrderRevenueAndCostsFiltersState>
    ) {
      state.filters = action.payload;
    },
    getRevenueAndExpenses: (state: RevenueAndExpensesState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getRevenueAndExpensesSuccess: (
      state: RevenueAndExpensesState,
      action: PayloadAction<RevenueAndExpensesReponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.data = action.payload;
    },
    getRevenueAndExpensesError: (
      state: RevenueAndExpensesState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchRevenueAndExpenses(
  filters: OrderRevenueAndCostsFiltersState,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    try {
      dispatch(setFilters(filters));
      dispatch(getRevenueAndExpenses());
      const response = await axiosInstance.get('/api/v1/revenueAndExpenses', {
        signal: abortController.signal,
        params: {
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter,
          country:
            filters.countries.length > 0
              ? filters.countries.map((c) => c.id).join(',')
              : undefined,
          shopId: filters.shopId > 0 ? filters.shopId : undefined,
          source:
            filters.sourceChannels.length > 0
              ? filters.sourceChannels.map((c) => c.id).join(',')
              : undefined,
          customerClass:
            filters.customers.length > 0
              ? filters.customers.map((c) => c.id).join(',')
              : undefined,
          profitBy: filters.profitBy
        }
      });
      dispatch(getRevenueAndExpensesSuccess(response.data));
    } catch (error) {
      dispatch(getRevenueAndExpensesError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setRevenueAndExpenses,
  setFilters,
  getRevenueAndExpenses,
  getRevenueAndExpensesSuccess,
  getRevenueAndExpensesError
} = slice.actions;
