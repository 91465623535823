import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { axiosInstance } from '../../utils/axiosConfig';
import { APIStatus, APIError } from '../../@types/APIStatus';
import { AreaChartFiltersState } from '../../@types/filterBars';
import { RevenueAndOrdersReponse } from '../../@types/responsesAPI';
import { RevenueAndOrdersState } from '../../@types/reduxStates';

const initialState: RevenueAndOrdersState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceId: 0,
    shopId: 0,
    countryId: 0,
    profit: 0,
    customerId: 0
  },
  APIStatus: APIStatus.IDLE,
  response: {
    chartTitle: '',
    days: [
      {
        date: format(subDays(new Date(), 0), 'yyyy-MM-dd'),
        costs: 0,
        margin: 0,
        orders: 0,
        returns: 0,
        returnsValue: 0,
        revenue: 0
      }
    ]
  }
};

const slice = createSlice({
  name: 'revenueAndOrders',
  initialState,
  reducers: {
    setFilters(
      state: RevenueAndOrdersState,
      action: PayloadAction<AreaChartFiltersState>
    ) {
      state.filters = action.payload;
    },
    getRevenueAndOrders: (state: RevenueAndOrdersState) => {
      state.APIStatus = APIStatus.PENDING;
    },
    getRevenueAndOrdersSuccess: (
      state: RevenueAndOrdersState,
      action: PayloadAction<RevenueAndOrdersReponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getRevenueAndOrdersError: (
      state: RevenueAndOrdersState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchRevenueAndOrders(
  filters: AreaChartFiltersState,
  abortController: AbortController,
  idArticle: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    dispatch(getRevenueAndOrders());
    try {
      const params = {
        idArticle: idArticle > 0 ? idArticle : undefined,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        countryId: filters.countryId,
        shopId: filters.shopId,
        sourceId: filters.sourceId,
        customerId: filters.customerId > 0 ? filters.customerId : undefined
      };
      const response = await axiosInstance.get(
        '/api/v2/dashboard/revenueAndOrders',
        {
          signal: abortController.signal,
          params
        }
      );
      dispatch(getRevenueAndOrdersSuccess(response.data));
    } catch (error) {
      dispatch(getRevenueAndOrdersError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  getRevenueAndOrders,
  getRevenueAndOrdersSuccess,
  getRevenueAndOrdersError
} = slice.actions;
