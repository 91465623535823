import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../utils/axiosConfig';
import { sortByProperty } from '../../utils/sortValues';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { AreaChartFiltersState } from '../../@types/filterBars';
import { ReturnsByProductState } from '../../@types/reduxStates';
import { ReturnsByProductResponse } from '../../@types/responsesAPI';
import { HeaderTableFilter } from '../../@types/filters';

const initialState: ReturnsByProductState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceId: 0,
    shopId: 0,
    countryId: 0,
    profit: 0,
    customerId: 0
  },
  APIStatus: APIStatus.IDLE,
  data: []
};

const slice = createSlice({
  name: 'returnsByProduct',
  initialState,
  reducers: {
    setReturnsByProduct: (
      state: ReturnsByProductState,
      action: PayloadAction<ReturnsByProductState>
    ) => {
      state = action.payload;
    },
    setFilters: (
      state: ReturnsByProductState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    sortReturnsByProduct: (
      state: ReturnsByProductState,
      action: PayloadAction<ReturnsByProductResponse>
    ) => {
      state.data = action.payload;
    },
    getReturnsByProduct: (state: ReturnsByProductState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getReturnsByProductSuccess: (
      state: ReturnsByProductState,
      action: PayloadAction<ReturnsByProductResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.data = action.payload;
    },
    getReturnsByProductError: (
      state: ReturnsByProductState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchReturnsByProduct(filters: AreaChartFiltersState) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    try {
      dispatch(getReturnsByProduct());
      const params: {
        from: string;
        till: string;
        source: number;
        shop: number;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        source: filters.sourceId,
        shop: filters.shopId
      };
      const response: AxiosResponse = await axiosInstance.get(
        '/rest/v1/dashboard/returnsByProduct',
        { params }
      );
      dispatch(getReturnsByProductSuccess(response.data));
    } catch (error) {
      console.debug(error);
      dispatch(getReturnsByProductError(error as APIError));
    }
  };
}

export function sortReturnsByProductBy(
  list: ReturnsByProductResponse,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortReturnsByProduct(orderedList));
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setReturnsByProduct,
  setFilters,
  sortReturnsByProduct,
  getReturnsByProduct,
  getReturnsByProductSuccess,
  getReturnsByProductError
} = slice.actions;
