import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIStatus, APIError } from '../../@types/APIStatus';
import { StockHealthFilterBarState } from '../../@types/filterBars';
import { StockHealthState } from '../../@types/reduxStates';
import { StockHealthResponse } from '../../@types/responsesAPI';
import { axiosInstance } from '../../utils/axiosConfig';

const initialState: StockHealthState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 0), 'yyyy-MM-dd')
    }
  },
  data: {
    totalListing: 0,
    totalStock: 0,
    stockTopSeller: 0,
    fillRateTotal: 0,
    fillRateTopSeller: 0,
    lastUpdate: ''
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'stockHealth',
  initialState,
  reducers: {
    setStockHealth: (
      state: StockHealthState,
      action: PayloadAction<StockHealthState>
    ) => {
      state = action.payload;
    },
    setFilters: (
      state: StockHealthState,
      action: PayloadAction<StockHealthFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getStockHealth: (state: StockHealthState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getStockHealthSuccess: (
      state: StockHealthState,
      action: PayloadAction<StockHealthResponse>
    ) => {
      state.data = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getStockHealthError: (
      state: StockHealthState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setStockHealth,
  setFilters,
  getStockHealth,
  getStockHealthSuccess,
  getStockHealthError
} = slice.actions;

export function fetchStockHealth(filters: StockHealthFilterBarState) {
  return async (dispatch: any) => {
    try {
      dispatch(setFilters(filters));
      dispatch(getStockHealth());
      // let response: AxiosResponse<any>;
      const params: {
        from: string;
        till: string;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter
      };
      const response = await axiosInstance.get('/rest/v2/stockHealth', {
        params
      });
      dispatch(getStockHealthSuccess(response.data));
    } catch (error) {
      dispatch(getStockHealthError(error as APIError));
    }
  };
}
