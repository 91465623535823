import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { PricesBySourceFilterState } from '../../@types/filterBars';
import { PricesBySourceState } from '../../@types/reduxStates';
import { PricesBySourceRow } from '../../@types/tableRows';
import { axiosInstance } from '../../utils/axiosConfig';
import { sortByProperty } from '../../utils/sortValues';

const initialState: PricesBySourceState = {
  filters: {
    countryId: 0
  },
  data: [],
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'pricesBySource',
  initialState,
  reducers: {
    setPricesBySource: (
      state: any,
      action: PayloadAction<Array<PricesBySourceRow>>
    ) => {
      state.data = action.payload;
    },
    setFilters: (
      state: any,
      action: PayloadAction<PricesBySourceFilterState>
    ) => {
      state.filters = action.payload;
    },
    getPricesBySource: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getPricesBySourceSuccess: (
      state: any,
      action: PayloadAction<Array<PricesBySourceRow>>
    ) => {
      state.data = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getPricesBySourceError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchPricesBySource(
  filters: PricesBySourceFilterState,
  articleId: number
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    dispatch(getPricesBySource());
    try {
      const params = {
        idArticle: articleId,
        countryId: filters.countryId
      };
      const response = await axiosInstance.get(
        '/rest/v1/article/dashboard/pricesBySource',
        {
          params
        }
      );
      dispatch(getPricesBySourceSuccess(response.data));
    } catch (error) {
      dispatch(getPricesBySourceError(error as APIError));
    }
  };
}

export function sortPricesBySourceBy(
  list: Array<PricesBySourceRow>,
  orderBy: string,
  order: 'asc' | 'desc'
) {
  const orderedList: Array<PricesBySourceRow> = sortByProperty(
    list,
    orderBy,
    order
  );
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(setPricesBySource(orderedList));
}

export const {
  setFilters,
  setPricesBySource,
  getPricesBySource,
  getPricesBySourceError,
  getPricesBySourceSuccess
} = slice.actions;

export default slice.reducer;

/* const formatSalesTable = async (
  data: Array<SalePriceByCountryRow>,
  country: string
): Promise<Array<SalePriceByCountryRow>> =>
  new Promise((resolve) => {
    /* const res = data.map(
            (row: SalePriceByCountryRow): SalePriceByCountryRow => (row)
        ); 

    let res: Array<SalePriceByCountryRow>;
    if (country.length > 0) {
      res = data.filter(
        (row: SalePriceByCountryRow) => row.country === country
      );
    } else {
      res = data;
    }
    setTimeout(() => {
      resolve(res);
    }, 200);
  }); */

// Reducer

// Actions
/* 

const data: Array<PricesBySourceRow> = [
  {
    source: {
      name: 'Stratic',
      srcImage: '/static/brand/logo_algoritmo.png'
    },
    price: 109.95,
    country: 'de-DE'
  },
  {
    source: {
      name: 'ebay',
      srcImage: '/static/brand/logo_algoritmo.png'
    },
    price: 129.95,
    country: 'de-DE'
  },
  {
    source: {
      name: 'Amazon',
      srcImage: '/static/brand/logo_algoritmo2.svg'
    },
    price: 129.95,
    country: 'de-DE'
  },
  {
    source: {
      name: 'Stratic',
      srcImage: '/static/brand/logo_algoritmo.png'
    },
    price: 109.95,
    country: 'de-DE'
  },
  {
    source: {
      name: 'ebay',
      srcImage: '/static/brand/logo_algoritmo2.svg'
    },
    price: 129.95,
    country: 'de-DE'
  },
  {
    source: {
      name: 'Amazon',
      srcImage: '/static/brand/logo_algoritmo.png'
    },
    price: 129.95,
    country: 'de-DE'
  },
  {
    source: {
      name: 'OTTO',
      srcImage: '/static/brand/logo_algoritmo2.svg'
    },
    price: 139.95,
    country: 'de-DE'
  },
  {
    source: {
      name: 'ebay',
      srcImage: '/static/brand/logo_algoritmo2.svg'
    },
    price: 129.95,
    country: 'gb-GB'
  },
  {
    source: {
      name: 'Amazon',
      srcImage: '/static/brand/logo_algoritmo.png'
    },
    price: 129.95,
    country: 'gb-GB'
  },
  {
    source: {
      name: 'OTTO',
      srcImage: '/static/brand/logo_algoritmo.png'
    },
    price: 139.95,
    country: 'gb-GB'
  },
  {
    source: {
      name: 'ebay',
      srcImage: '/static/brand/logo_algoritmo2.svg'
    },
    price: 129.95,
    country: 'es-ES'
  },
  {
    source: {
      name: 'Amazon',
      srcImage: '/static/brand/logo_algoritmo.png'
    },
    price: 129.95,
    country: 'es-ES'
  },
  {
    source: {
      name: 'OTTO',
      srcImage: '/static/brand/logo_algoritmo2.svg'
    },
    price: 139.95,
    country: 'es-ES'
  }
]; */
