import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

// guards
import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';
import { RouteItem } from '.';
import Order from '../views/Order';
import Product from '../views/Product';
import PurchaseDetail from '../views/PurchaseDetail';
import Maintenance from '../views/Maintenance';

// ----------------------------------------------------------------------

/* const LANGS = [
  {
    value: 'de-DE',
    url: '/page/dashboard/produkte/dashboard'
    // url: '/dashboard/produkte/dashboard'
  },
  {
    value: 'en-GB',
    url: '/page/dashboard/product/dashboard'
    // url: '/dashboard/product/dashboard'
  }
]; */

// @todo: dynamic
// const langStorage = localStorage.getItem('i18nextLng');
// const currentLang =
// LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

const AppRoutes: RouteItem = {
  path: '/',
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: () => <Redirect to="/page/dashboard" />
    },
    {
      exact: true,
      path: '/maintenance',
      component: () => <Maintenance />
    },
    {
      exact: true,
      path: '/product/:id/:tab',
      component: () => <Product />
    },
    {
      exact: true,
      path: '/order/:id',
      component: () => <Order />
    },
    {
      exact: true,
      path: '/purchase/:id',
      component: () => <PurchaseDetail />
    },
    {
      exact: true,
      path: '/page/:page',
      component: lazy(() => import('../views/LoadComponent'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export { AppRoutes };
