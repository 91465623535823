import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// redux
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { persistor, store } from './redux/store';
// routes
import routes, { renderRoutes } from './routes';
// theme
import ThemeConfig from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';

import JwtProvider from './components/authentication/JwtProvider';
import NotistackProvider from './components/NotistackProvider';
import { ConstantsProvider } from './ConstantsContext';
import AxiosProvider from './context/AxiosProvider';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

export default function App() {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <ThemeConfig>
            <RtlLayout>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <NotistackProvider>
                  <JwtProvider>
                    <Router history={history}>
                      <AxiosProvider>
                        <ConstantsProvider>
                          <Settings />
                          <ScrollToTop />
                          {renderRoutes(routes)}
                        </ConstantsProvider>
                      </AxiosProvider>
                    </Router>
                  </JwtProvider>
                </NotistackProvider>
              </LocalizationProvider>
            </RtlLayout>
          </ThemeConfig>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}
