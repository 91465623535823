import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TablePagination,
  useMediaQuery
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import _debounce from 'lodash/debounce';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import { SortBy } from '../../@types/filters';
import useActivityStream from '../../hooks/useActivityStream';
import { ActivityStreamFilterBar } from './ActivityStreamFilterBar';
import ActivityStreamTable from './ActivityStreamTable';
import EmailModal from './EmailModal';

type Props = {
  title?: string;
};

export default function ActivityStream({ title = 'activity_stream' }: Props) {
  const { t: translate } = useTranslation(['component']);
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    response: { count }
  } = useActivityStream();

  const classes = TablePaginationClasses();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [rowsPerPageOpts, setRowsPerPageOpts] = useState<Array<number>>([
    5, 10, 25
  ]);

  const [sort, setSort] = useState<SortBy>({ by: 'date', order: 'desc' });

  useEffect(() => {
    if (count <= 5) setRowsPerPageOpts([5]);
    if (count > 5 && count <= 10) setRowsPerPageOpts([5, 10]);
    if (count > 10) setRowsPerPageOpts([5, 10, 25]);
  }, [count]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <ActivityStreamFilterBar
            rowsPerPage={rowsPerPage}
            setInitialPage={() => setPage(0)}
          />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={translate(title)} />
            <CardContent>
              <ActivityStreamTable
                sortBy={sort}
                onSortChange={setSort}
                rowsPerPage={rowsPerPage}
                page={page}
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <TablePagination
                  component="div"
                  className={classes.root}
                  rowsPerPageOptions={rowsPerPageOpts}
                  labelRowsPerPage={
                    !isMobile ? translate('global:rows_per_page') : ''
                  }
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <EmailModal />
    </div>
  );
}
