import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIStatus, APIError } from '../../@types/APIStatus';
import {
  StockHealthFilterBarState,
  StockOverviewFilterBarState
} from '../../@types/filterBars';
import { StockOverviewState } from '../../@types/reduxStates';
import { StockOverviewResponse } from '../../@types/responsesAPI';
import { axiosInstance } from '../../utils/axiosConfig';

const initialState: StockOverviewState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 0), 'yyyy-MM-dd')
    }
  },
  data: {
    stockHealth: {
      fillRateTopSeller: 0,
      stockTopSeller: 0,
      fillRateTotal: 0,
      totalListing: 0,
      totalStock: 0,
      lastUpdate: ''
    },
    stockOverview: {
      total: [],
      sellable: []
    }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'stockOverview',
  initialState,
  reducers: {
    setStockOverview: (
      state: StockOverviewState,
      action: PayloadAction<StockOverviewState>
    ) => {
      state = action.payload;
    },
    setFilters: (
      state: StockOverviewState,
      action: PayloadAction<StockOverviewFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getStockOverview: (state: StockOverviewState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getStockOverviewSuccess: (
      state: StockOverviewState,
      action: PayloadAction<StockOverviewResponse>
    ) => {
      state.data = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getStockOverviewError: (
      state: StockOverviewState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setStockOverview,
  setFilters,
  getStockOverview,
  getStockOverviewSuccess,
  getStockOverviewError
} = slice.actions;

export function fetchStockOverview(
  filters: StockHealthFilterBarState,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    try {
      dispatch(setFilters(filters));
      dispatch(getStockOverview());
      const params: {
        from: string;
        till: string;
      } = {
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter
      };
      const response = await axiosInstance.get('/api/v2/stockOverview', {
        signal: abortController.signal,
        params
      });
      dispatch(getStockOverviewSuccess(response.data));
    } catch (error) {
      dispatch(getStockOverviewError(error as APIError));
    }
  };
}
