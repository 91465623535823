import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import { DateRangeFilter } from '../../@types/filters';
import SelectTimePeriod from '../filters/SelectTimePeriod';
import ProfitRow from './ProfitRow';
import useProfit from '../../hooks/useProfit';
import { APIStatus } from '../../@types/APIStatus';
import useArticlesDetail from '../../hooks/useArticlesDetail';
import { FilterSelect } from '../items/filters/FilterSelect';
import CardTableStatus from '../general/CardTableStatus';

function Profit() {
  const { t: translate } = useTranslation(['component']);
  const {
    response,
    filters,
    updateProfit,
    error,
    APIStatus: state
  } = useProfit();
  const {
    article: { articleId }
  } = useArticlesDetail();

  const [timePeriod, setTimePeriod] = useState<DateRangeFilter>(
    filters.timePeriod
  );

  const [profitBy, setProfitBy] = useState<number>(0);

  useEffect(() => {
    updateProfit({ timePeriod, profitBy }, `${articleId}`);
  }, [timePeriod, profitBy, articleId]);

  return (
    <Card>
      <CardHeader title={translate('profit')} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            container
            spacing={2}
            item
            xs={12}
            justifyContent="space-between"
          >
            <Grid item md={4} xs={6}>
              <FilterSelect
                selected={profitBy}
                title={translate('profit_by')}
                labels={[translate('by_order'), translate('by_invoice')]}
                onChangeSelected={setProfitBy}
              />
            </Grid>
            <Grid item lg={5} xs={6}>
              <SelectTimePeriod
                startFilter={filters.timePeriod.startFilter}
                endFilter={filters.timePeriod.endFilter}
                onChangePeriod={setTimePeriod}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ position: 'relative' }}>
            <CardTableStatus
              isLoading={state === APIStatus.PENDING}
              isError={error !== undefined}
              isNoResult={false}
            />
            <ProfitRow
              label={translate('units_sold')}
              value={response.unitsSold}
              type="numeric"
            />
            <ProfitRow
              label={translate('component:revenue_netto')}
              value={response.revenueNetto}
              type="currency"
            />
            <ProfitRow
              label={translate('component:revenue_brutto')}
              value={response.revenueBrutto}
              type="currency"
            />
            <ProfitRow
              label={translate('component:credit_note_net')}
              value={response.creditPayout}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:net_after_credit_payout')}
              value={response.netAfterCreditPayout}
              type="currency"
              dark
            />
            <ProfitRow
              label={translate('component:brutto_after_credit_payout')}
              value={response.bruttoAfterCreditPayout}
              type="currency"
              dark
            />
            <ProfitRow
              label={translate('component:discount')}
              value={response.discount}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:cancellations_net')}
              value={response.cancellations}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:net_after_cancellations')}
              value={response.netAfterCancellations}
              type="currency"
              dark
            />
            <ProfitRow
              label={translate('component:gross_after_cancellations')}
              value={response.grossAfterCancellations}
              type="currency"
              dark
            />
            <ProfitRow
              label={translate('component:units_returned')}
              value={response.unitsReturn}
              type="numeric"
            />
            <ProfitRow
              label={translate('component:units_sold_after_returns')}
              value={response.unitsSoldAfterReturns}
              type="numeric"
            />
            <ProfitRow
              label={translate('component:returns_net')}
              value={response.returns}
              type="currency"
              infoStyle
            />
            <ProfitRow
              label={translate('component:returns_rate')}
              value={response.returnsRate}
              type="percent"
            />
            <ProfitRow
              label={translate('component:revenueAfterCancellationsIncRetoure')}
              value={response.revenueAfterCancellationsIncRetoure}
              type="currency"
            />
            <ProfitRow
              label={translate('component:shipping_costs_net')}
              value={response.shippingCosts}
              type="currency"
              infoStyle
            />
            <ProfitRow
              label={translate('component:transport_costs_net')}
              value={response.transportCosts}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:product_costs_net')}
              value={response.productCosts}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:customs_net')}
              value={response.customs}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:db1')}
              value={response.db1}
              type="currency"
              dark
            />
            <ProfitRow
              label={translate('component:db1_rate')}
              value={response.db1Rate}
              type="percent"
            />
            <ProfitRow
              label={translate('component:db1PerItem')}
              value={response.db1PerItem}
              type="currency"
            />
            <ProfitRow
              label={translate('component:operating_costs_net')}
              value={response.operatingCosts}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:delivery_costs_net')}
              value={response.deliveryCosts}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:storage_costs_net')}
              value={response.storageCosts}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:db2')}
              value={response.db2}
              type="currency"
              dark
            />
            <ProfitRow
              label={translate('component:db2_rate')}
              value={response.db2Rate}
              type="percent"
            />
            <ProfitRow
              label={translate('component:db2PerItem')}
              value={response.db2PerItem}
              type="currency"
            />
            <ProfitRow
              label={translate('component:advertising_costs_net')}
              value={response.advertisingCosts}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:marceplaces_fee_net')}
              value={response.marketplaceFees}
              type="currency"
              substract
            />
            <ProfitRow
              label={translate('component:db3')}
              value={response.db3}
              type="currency"
              dark
            />
            <ProfitRow
              label={translate('component:db3_rate')}
              value={response.db3Rate}
              type="percent"
            />
            <ProfitRow
              label={translate('component:db3PerItem')}
              value={response.db3PerItem}
              type="currency"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Profit;
