import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance } from '../../utils/axiosConfig';
import {
  ChannelProducts,
  APIChannelProductsState
} from '../../@types/products';
import { APIStatus, APIError } from '../../@types/APIStatus';
import { AreaChartFiltersState } from '../../@types/filterBars';

const initialState: APIChannelProductsState = {
  channelProducts: {
    labels: [],
    chartData: []
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'channelProducts',
  initialState,
  reducers: {
    getChannelProducts(state: APIChannelProductsState) {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getChannelProductsSuccess(
      state: APIChannelProductsState,
      action: PayloadAction<ChannelProducts>
    ) {
      state.channelProducts = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getChannelProductsError(
      state: APIChannelProductsState,
      action: PayloadAction<APIError>
    ) {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchSalesByReferrer(
  filters: AreaChartFiltersState,
  articleId: string = '',
  topTen: boolean = false
) {
  return async (dispatch: any) => {
    try {
      dispatch(getChannelProducts());
      const response = await axiosInstance.get(`/rest/v2/salesByReferrer`, {
        params: {
          articleId: articleId.length > 0 ? articleId : undefined,
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter,
          countryId: filters.countryId > 0 ? filters.countryId : undefined,
          shopId: filters.shopId > 0 ? filters.shopId : undefined,
          sourceId: filters.sourceId > 0 ? filters.sourceId : undefined,
          topTen: topTen ? 1 : 0
        }
      });
      dispatch(getChannelProductsSuccess(response.data));
    } catch (error) {
      dispatch(getChannelProductsError(error as APIError));
    }
  };
}

export function fetchChannelProducts(
  filters: AreaChartFiltersState,
  articleId: string,
  topTen: boolean
) {
  return async (dispatch: any) => {
    /* let fullQuery = `/rest/v1/dashboard/${
      topTen ? 'toptenproducts' : 'saleschannel'
    }?`;
    fullQuery = `${fullQuery}from=${filters.timePeriod.startFilter}&till=${filters.timePeriod.endFilter}`;
    fullQuery = `${fullQuery}&countryId=${filters.countryId}`;
    fullQuery = `${fullQuery}&sourceId=${filters.sourceId}`;
    fullQuery = `${fullQuery}&shopId=${filters.shopId}`;
    fullQuery = `${fullQuery}&articleId=${articleId}`; */
    try {
      const params: {
        articleId: string;
        from: string;
        till: string;
        countryId?: number;
        shopId?: number;
        sourceId?: number;
      } = {
        articleId,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        countryId: filters.countryId,
        shopId: filters.shopId,
        sourceId: filters.sourceId
      };
      dispatch(getChannelProducts());
      const response = await axiosInstance.get(
        `/rest/v1/dashboard/${topTen ? 'toptenproducts' : 'saleschannel'}?`,
        { params }
      );
      dispatch(getChannelProductsSuccess(response.data));
    } catch (error) {
      dispatch(getChannelProductsError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  // setChannelProducts,
  getChannelProducts,
  getChannelProductsSuccess,
  getChannelProductsError
} = slice.actions;
