import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkDispatch
} from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { Dispatch } from 'react';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { DashboardTabState } from '../../@types/reduxStates';
import {
  TabFilterState,
  PricesBySourceFilterState
} from '../../@types/filterBars';
import { DashboardTabResponse } from '../../@types/responsesAPI';
import { PricesBySourceRow } from '../../@types/tableRows';
import { sortByProperty } from '../../utils/sortValues';
import { NotificationType } from '../../@types/notifications';
import { HeaderTableFilter } from '../../@types/filters';
import { axiosInstance } from '../../utils/axiosConfig';

const initialState: DashboardTabState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    }
  },
  data: {
    /* revenueAndOrders: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        sourceId: 0,
        shopId: 0,
        countryId: 0,
        profit: 0,
        customerId: 0
      },
      APIStatus: APIStatus.IDLE,
      response: {
        chartTitle: '',
        days: [
          {
            date: format(subDays(new Date(), 0), 'yyyy-MM-dd'),
            costs: 0,
            margin: 0,
            orders: 0,
            returns: 0,
            returnsValue: 0,
            revenue: 0
          }
        ]
      }
    }, */
    /* salesByCountry: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        sourceId: 0,
        shopId: 0,
        countryId: 0,
        profit: 0,
        customerId: 0
      },
      data: [],
      APIStatus: APIStatus.IDLE
    }, */
    /* productHealth: {
      filters: {
        timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        },
        sourceId: 0,
        countryId: 0,
        shopId: 0,
        profit: 0,
        customerId: 0
      },
      data: {
        columnOne: {
          percent: 0,
          units: 0,
          returnsRate: 0
        },
        columnTwo: {
          percent: 0,
          units: 0,
          db3Rate: 0
        },
        columnThree: {
          percent: 0,
          units: 0,
          revenueShare: 0
        }
      },
      APIStatus: APIStatus.IDLE
    }, */
    priceList: {
      filters: {
        /* timePeriod: {
          startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
          endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
        }, */
        countryId: 0
      },
      data: [],
      APIStatus: APIStatus.IDLE
    },
    recentActivity: {
      data: [],
      APIStatus: APIStatus.IDLE
    }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'dashboardTab',
  initialState,
  reducers: {
    resetDashboardTab: (state: DashboardTabState) => {
      state.APIStatus = APIStatus.IDLE;
    },
    getDashboardTab: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getDashboardTabSuccess: (
      state: DashboardTabState,
      action: PayloadAction<DashboardTabResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      // state.data.revenueAndOrders.response = action.payload.revenueAndOrders;
      state.data.priceList.data = action.payload.priceList;
      // state.data.productHealth.data = action.payload.productHealth;
      // state.data.salesByCountry.data = action.payload.salesByCountry;
      state.data.recentActivity.data = action.payload.recentActivity;
    },
    getDashboardTabError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    },
    // Revenue and orders
    /* setRevenueAndOrdersFilters(
      state: any,
      action: PayloadAction<AreaChartFiltersState>
    ) {
      state.data.revenueAndOrders.filters = action.payload;
    },
    getRevenueAndOrders: (state: any) => {
      state.data.revenueAndOrders.APIStatus = APIStatus.PENDING;
      state.data.revenueAndOrders.error = undefined;
    },
    getRevenueAndOrdersSuccess: (
      state: DashboardTabState,
      action: PayloadAction<RevenueAndOrdersReponse>
    ) => {
      state.data.revenueAndOrders.APIStatus = APIStatus.FULFILLED;
      state.data.revenueAndOrders.response = action.payload;
    },
    getRevenueAndOrdersError: (state: any, action: PayloadAction<APIError>) => {
      state.data.revenueAndOrders.APIStatus = APIStatus.REJECTED;
      state.data.revenueAndOrders.error = action.payload;
    }, */
    // Sales By Country
    /* setSalesByCountry: (
      state: DashboardTabState,
      action: PayloadAction<SalesByCountryResponse>
    ) => {
      state.data.salesByCountry.data = action.payload;
    },
    setSalesByCountryFilters(
      state: DashboardTabState,
      action: PayloadAction<AreaChartFiltersState>
    ) {
      state.data.salesByCountry.filters = action.payload;
    },
    getSalesByCountry: (state: DashboardTabState) => {
      state.data.salesByCountry.APIStatus = APIStatus.PENDING;
      state.data.salesByCountry.error = undefined;
    },
    getSalesByCountrySuccess: (
      state: DashboardTabState,
      action: PayloadAction<SalesByCountryResponse>
    ) => {
      state.data.salesByCountry.APIStatus = APIStatus.FULFILLED;
      state.data.salesByCountry.data = action.payload;
    },
    getSalesByCountryError: (
      state: DashboardTabState,
      action: PayloadAction<APIError>
    ) => {
      state.data.salesByCountry.APIStatus = APIStatus.REJECTED;
      state.data.salesByCountry.error = action.payload;
    }, */
    // ProductHealth
    /* setProductHealthFilters: (
      state: DashboardTabState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.data.productHealth.filters = action.payload;
    },
    getProductHealth: (state: DashboardTabState) => {
      state.data.productHealth.APIStatus = APIStatus.PENDING;
    },
    getProductHealthSuccess: (
      state: DashboardTabState,
      action: PayloadAction<ProductHealthResponse>
    ) => {
      state.data.productHealth.APIStatus = APIStatus.FULFILLED;
      state.data.productHealth.data = action.payload;
    },
    getProductHealthError: (
      state: DashboardTabState,
      action: PayloadAction<APIError>
    ) => {
      state.data.productHealth.APIStatus = APIStatus.REJECTED;
      state.data.productHealth.error = action.payload;
    }, */
    // PriceList
    setPriceList: (
      state: DashboardTabState,
      action: PayloadAction<Array<PricesBySourceRow>>
    ) => {
      state.data.priceList.data = action.payload;
    },
    setPriceListFilters: (
      state: DashboardTabState,
      action: PayloadAction<PricesBySourceFilterState>
    ) => {
      state.data.priceList.filters = action.payload;
    },
    getPriceList: (state: DashboardTabState) => {
      state.data.priceList.APIStatus = APIStatus.PENDING;
      state.data.priceList.error = undefined;
    },
    getPriceListSuccess: (
      state: DashboardTabState,
      action: PayloadAction<Array<PricesBySourceRow>>
    ) => {
      state.data.priceList.data = action.payload;
      state.data.priceList.APIStatus = APIStatus.FULFILLED;
    },
    getPriceListError: (
      state: DashboardTabState,
      action: PayloadAction<APIError>
    ) => {
      state.data.priceList.APIStatus = APIStatus.REJECTED;
      state.data.priceList.error = action.payload;
    },
    // Notifications
    setNotifications: (
      state: DashboardTabState,
      action: PayloadAction<Array<NotificationType>>
    ) => {
      state.data.recentActivity.data = action.payload;
    },
    getNotifications: (state: DashboardTabState) => {
      state.data.recentActivity.APIStatus = APIStatus.PENDING;
      state.data.recentActivity.error = undefined;
    },
    getNotificationsSuccess: (
      state: DashboardTabState,
      action: PayloadAction<Array<NotificationType>>
    ) => {
      state.data.recentActivity.APIStatus = APIStatus.FULFILLED;
      state.data.recentActivity.data = action.payload;
    },
    getNotificationsError: (
      state: DashboardTabState,
      action: PayloadAction<APIError>
    ) => {
      state.data.recentActivity.APIStatus = APIStatus.REJECTED;
      state.data.recentActivity.error = action.payload;
    }
  }
});

export function fetchDashboardTab(
  filter: TabFilterState,
  articleId: number,
  language: string = 'en-GB'
) {
  return async (dispatch: any) => {
    try {
      const params: {
        idArticle: number;
        from: string;
        till: string;
        lang: string;
      } = {
        idArticle: articleId,
        from: filter.timePeriod.startFilter,
        till: filter.timePeriod.endFilter,
        lang: language
      };
      dispatch(getDashboardTab());
      const response = await axiosInstance.get(
        '/rest/v1/article/tab/dashboard',
        {
          params
        }
      );
      dispatch(getDashboardTabSuccess(response.data));
    } catch (error) {
      dispatch(getDashboardTabError(error as APIError));
    }
  };
}

/* export function fetchRevenueAndOrders(
  filters: AreaChartFiltersState,
  abortController: AbortController,
  idArticle: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setRevenueAndOrdersFilters(filters));
    dispatch(getRevenueAndOrders());
    try {
      const params = {
        idArticle: idArticle > 0 ? idArticle : undefined,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        countryId: filters.countryId,
        shopId: filters.shopId,
        sourceId: filters.sourceId,
        customerId: filters.customerId > 0 ? filters.customerId : undefined
      };
      const response = await axios.get('/api/v2/dashboard/revenueAndOrders', {
        signal: abortController.signal,
        params
      });
      dispatch(getRevenueAndOrdersSuccess(response.data));
    } catch (error) {
      dispatch(getRevenueAndOrdersError(error as APIError));
    }
  };
} */

/* export function fetchSalesByCountry(
  filters: AreaChartFiltersState,
  articleId: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setSalesByCountryFilters(filters));
    try {
      dispatch(getSalesByCountry());

      const params = {
        idArticle: articleId > 0 ? articleId : undefined,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        sourceId: filters.sourceId > 0 ? filters.sourceId : undefined,
        shopId: filters.shopId > 0 ? filters.shopId : undefined,
        customerId: filters.customerId > 0 ? filters.customerId : undefined
      };
      const response = await axios.get('/api/v2/article/salesByCountry', {
        params
      });
      dispatch(getSalesByCountrySuccess(response.data));
    } catch (error) {
      dispatch(getSalesByCountryError(error as APIError));
    }
  };
} */

/* export function fetchProductHealth(
  filters: AreaChartFiltersState,
  articleId: number
) {
  return async (dispatch: any) => {
    dispatch(setProductHealthFilters(filters));
    try {
      dispatch(getProductHealth());
      const params = {
        idArticle: articleId,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shopId: filters.shopId,
        sourceId: filters.sourceId,
        customerId: filters.customerId
      };
      const response = await axios.get('/api/v1/dashboard/productHealth', {
        params
      });
      dispatch(getProductHealthSuccess(response.data));
    } catch (error) {
      dispatch(getProductHealthError(error as APIError));
    }
  };
} */

export function fetchPriceList(
  filters: PricesBySourceFilterState,
  articleId: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setPriceListFilters(filters));
    dispatch(getPriceList());
    try {
      const response = await axiosInstance.get(
        '/api/v2/article/productPriceList',
        {
          params: {
            idArticle: articleId > 0 ? articleId : undefined,
            country: filters.countryId > 0 ? filters.countryId : undefined
          }
        }
      );
      dispatch(getPriceListSuccess(response.data));
    } catch (error) {
      dispatch(getPriceListError(error as APIError));
    }
  };
}

export function fetchNotifications(language: string, articleId?: number) {
  return async (dispatch: any) => {
    dispatch(getNotifications());
    try {
      const params = {
        idArticle: articleId,
        lang: language
      };
      const response = await axiosInstance.get(
        '/api/v1/article/dashboard/activity',
        {
          params
        }
      );
      dispatch(getNotificationsSuccess(response.data));
    } catch (error) {
      dispatch(getNotificationsError(error as APIError));
    }
  };
}

export function sortPriceListBy(
  list: Array<PricesBySourceRow>,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType?: HeaderTableFilter
) {
  const orderedList: Array<PricesBySourceRow> = sortByProperty(
    list,
    orderBy,
    order,
    orderType
  );
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(setPriceList(orderedList));
}

// TODO
export function sortNotificationsBy(
  list: Array<NotificationType>,
  orderBy: string,
  order: 'asc' | 'desc'
) {
  const orderedList: Array<NotificationType> = sortByProperty(
    list,
    orderBy,
    order
  );
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(setNotifications(orderedList));
}

export default slice.reducer;

export const {
  resetDashboardTab,
  getDashboardTab,
  getDashboardTabSuccess,
  getDashboardTabError,

  /* getRevenueAndOrders,
  getRevenueAndOrdersSuccess,
  getRevenueAndOrdersError,
  setRevenueAndOrdersFilters,
 */
  /* setSalesByCountry,
  getSalesByCountry,
  getSalesByCountrySuccess,
  getSalesByCountryError,
  setSalesByCountryFilters, */

  /* getProductHealth,
  getProductHealthSuccess,
  getProductHealthError,
  setProductHealthFilters, */

  setPriceList,
  getPriceList,
  getPriceListSuccess,
  getPriceListError,
  setPriceListFilters,

  setNotifications,
  getNotifications,
  getNotificationsSuccess,
  getNotificationsError
} = slice.actions;
