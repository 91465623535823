import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { axiosInstance } from '../../utils/axiosConfig';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { ProductHealthState } from '../../@types/reduxStates';
import { ProductHealthResponse } from '../../@types/responsesAPI';
import { AreaChartFiltersState } from '../../@types/filterBars';

// ----------------------------------------------------------------------

const initialState: ProductHealthState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceId: 0,
    shopId: 0,
    countryId: 0,
    profit: 0,
    customerId: 0
  },
  response: {
    columnOne: {
      percent: 0,
      units: 0,
      returnsRate: 0
    },
    columnTwo: {
      percent: 0,
      units: 0,
      db3Rate: 0
    },
    columnThree: {
      percent: 0,
      units: 0,
      revenueShare: 0
    }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'productHealth',
  initialState,
  reducers: {
    setFilters: (
      state: ProductHealthState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    getProductHealth: (state: ProductHealthState) => {
      state.APIStatus = APIStatus.PENDING;
    },
    getProductHealthSuccess: (
      state: ProductHealthState,
      action: PayloadAction<ProductHealthResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getProductHealthError: (
      state: ProductHealthState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

/* export function fetchProductHealth(
  filters: AreaChartFiltersState,
  articleId: number
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    try {
      dispatch(getProductHealth());
      const params = {
        idArticle: articleId,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shopId: filters.shopId,
        sourceId: filters.sourceId
      };
      const response = await axios.get('/api/v1/dashboard/productHealth', {
        params
      });
      dispatch(getProductHealthSuccess(response.data));
    } catch (error) {
      dispatch(getProductHealthError(error as APIError));
    }
  };
} */

export function fetchProductHealth(
  filters: AreaChartFiltersState,
  abortController: AbortController,
  articleId: number
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    try {
      dispatch(getProductHealth());
      const params = {
        idArticle: articleId,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        shopId: filters.shopId,
        sourceId: filters.sourceId,
        customerId: filters.customerId
      };
      const response = await axiosInstance.get(
        '/api/v1/dashboard/productHealth',
        {
          signal: abortController.signal,
          params
        }
      );
      dispatch(getProductHealthSuccess(response.data));
    } catch (error) {
      dispatch(getProductHealthError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  getProductHealth,
  getProductHealthSuccess,
  getProductHealthError
} = slice.actions;
