import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { useMemo } from 'react';
import useLocales from '../../hooks/useLocales';
import { textStyles } from '../../theme/TextClasses';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles<Theme, { dark?: boolean }>((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: ({ dark }) => (dark ? theme.palette.grey[200] : 'white')
  },
  valueText: {
    textAlign: 'end',
    fontWeight: 600
  }
}));

const getColor = (
  type: string,
  value: number,
  substract: boolean,
  theme: Theme
): string => {
  if (type === 'numeric' || type === 'percent')
    return theme.palette.text.primary;
  return substract
    ? value > 0
      ? theme.palette.error.main
      : theme.palette.text.primary
    : value >= 0
    ? theme.palette.text.primary
    : theme.palette.error.main;
};

type Props = {
  label: string;
  value: number;
  type: 'currency' | 'percent' | 'numeric';
  sign?: boolean;
  substract?: boolean;
  dark?: boolean;
  infoStyle?: boolean;
};

function ProfitRow({
  label,
  value,
  type,
  sign = false,
  substract = false,
  dark = false,
  infoStyle = false
}: Props) {
  const textClasses = textStyles({});
  const { currentLang } = useLocales();
  const theme: Theme = useTheme();
  const classes = useStyles({ dark });
  const { user } = useAuth();
  const currencyISO = user.currency?.iso ?? 'EUR';

  const euroFormatter = useMemo(
    () =>
      new Intl.NumberFormat(currentLang.value, {
        style: 'currency',
        currency: currencyISO,
        maximumFractionDigits: 2,
        // @ts-ignore
        signDisplay: sign ? 'exceptZero' : ('auto' as const)
      }),
    [currentLang.value, currencyISO]
  );

  const percentFormatter = useMemo(
    () =>
      new Intl.NumberFormat(currentLang.value, {
        style: 'percent',
        maximumFractionDigits: 1,
        // @ts-ignore
        signDisplay: sign ? 'exceptZero' : ('auto' as const)
      }),
    [currentLang.value, sign]
  );

  const formattedValue = useMemo(() => {
    if (type === 'currency')
      return euroFormatter.format(substract && value > 0 ? -value : value);
    if (type === 'percent') return percentFormatter.format(value);
    return `${value}`;
  }, [type, value, substract, euroFormatter, percentFormatter]);

  const isInfoLabel = `${textClasses.ellipsis} ${
    infoStyle ? textClasses.infoStyle : ''
  }`;
  const isInfoValue = `${classes.valueText} ${
    infoStyle ? textClasses.infoStyle : ''
  }`;

  return (
    <Grid container className={classes.root}>
      <Grid item md={6} xs={12}>
        <Typography variant="body1" fontWeight={500} className={isInfoLabel}>
          {label}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography
          variant="h6"
          className={isInfoValue}
          sx={{ color: getColor(type, value, substract, theme) }}
        >
          {formattedValue}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ProfitRow;
