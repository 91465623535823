import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import { sortByProperty } from '../../utils/sortValues';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { FilterBarState } from '../../@types/filterBars';
import { OrdersByStatusState, Pagination } from '../../@types/reduxStates';
import { OrdersByStatusResponse } from '../../@types/responsesAPI';
import { HeaderTableFilter, SortBy } from '../../@types/filters';
import { OrdersByStatusRow } from '../../@types/tableRows';
import { axiosInstance } from '../../utils/axiosConfig';

const initialState: OrdersByStatusState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    source: 0,
    country: 0,
    shop: 0
  },
  lang: 'de-DE',
  APIStatus: APIStatus.IDLE,
  response: {
    rows: [],
    total: {
      orders: 0,
      revenue: 0,
      tendency: 0
    },
    totalCount: 0
  }
};

const slice = createSlice({
  name: 'ordersByStatus',
  initialState,
  reducers: {
    setOrdersByStatus: (
      state: OrdersByStatusState,
      action: PayloadAction<OrdersByStatusState>
    ) => {
      state = action.payload;
    },
    setFilters: (
      state: OrdersByStatusState,
      action: PayloadAction<FilterBarState>
    ) => {
      state.filters = action.payload;
    },
    sortOrdersByStatus: (
      state: OrdersByStatusState,
      action: PayloadAction<Array<OrdersByStatusRow>>
    ) => {
      state.response.rows = action.payload;
    },
    getOrdersByStatus: (state: OrdersByStatusState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOrdersByStatusSuccess: (
      state: OrdersByStatusState,
      action: PayloadAction<OrdersByStatusResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getOrdersByStatusError: (
      state: OrdersByStatusState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchOrdersByStatus(
  filterBarState: FilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  lang: string,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filterBarState));
    try {
      dispatch(getOrdersByStatus());
      const params = {
        skip,
        limit,
        by,
        order,
        lang,
        from: filterBarState.timePeriod.startFilter,
        till: filterBarState.timePeriod.endFilter
      };
      const response: AxiosResponse = await axiosInstance.get(
        '/api/v1/ordersByStatus',
        { signal: abortController.signal, params }
      );
      dispatch(getOrdersByStatusSuccess(response.data));
    } catch (error) {
      dispatch(getOrdersByStatusError(error as APIError));
    }
  };
}

export function sortOrdersByStatusBy(
  list: Array<OrdersByStatusRow>,
  orderBy: string,
  order: 'asc' | 'desc',
  orderType: HeaderTableFilter
) {
  const orderedList = sortByProperty(list, orderBy, order, orderType);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortOrdersByStatus(orderedList));
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setOrdersByStatus,
  setFilters,
  sortOrdersByStatus,
  getOrdersByStatus,
  getOrdersByStatusSuccess,
  getOrdersByStatusError
} = slice.actions;
