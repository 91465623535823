import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../utils/axiosConfig';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { WidgetFiltersState } from '../../@types/filterBars';
import { OrdersByChannelState } from '../../@types/reduxStates';
import { OrdersByChannelResponse } from '../../@types/responsesAPI';

const initialState: OrdersByChannelState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    }
  },
  APIStatus: APIStatus.IDLE,
  response: {
    chart: [],
    total: {
      name: '',
      margin: 0,
      marginBefore: 0,
      marginTending: 0,
      marginPercent: 0,
      revenue: 0,
      revenueBefore: 0,
      revenuePercent: 0,
      revenueTending: 0
    }
  }
};

const slice = createSlice({
  name: 'ordersByChannel',
  initialState,
  reducers: {
    setOrdersByChannel: (
      state: OrdersByChannelState,
      action: PayloadAction<OrdersByChannelState>
    ) => {
      state = action.payload;
    },
    setOrdersByChannelFilters: (
      state: OrdersByChannelState,
      action: PayloadAction<WidgetFiltersState>
    ) => {
      state.filters = action.payload;
    },
    getOrdersByChannel: (state: OrdersByChannelState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOrdersByChannelSuccess: (
      state: OrdersByChannelState,
      action: PayloadAction<OrdersByChannelResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getOrdersByChannelError: (
      state: OrdersByChannelState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchOrdersByChannel(
  filters: WidgetFiltersState,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setOrdersByChannelFilters(filters));
    try {
      dispatch(getOrdersByChannel());
      const params = {
        from: filters.timePeriod ? filters.timePeriod.startFilter : undefined,
        till: filters.timePeriod ? filters.timePeriod.endFilter : undefined
      };
      const response: AxiosResponse = await axiosInstance.get(
        '/api/v1/ordersByChannel',
        { signal: abortController.signal, params }
      );
      dispatch(getOrdersByChannelSuccess(response.data));
    } catch (error) {
      dispatch(getOrdersByChannelError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setOrdersByChannel,
  setOrdersByChannelFilters,
  getOrdersByChannel,
  getOrdersByChannelSuccess,
  getOrdersByChannelError
} = slice.actions;
