import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { axiosInstance } from '../../utils/axiosConfig';
import { ChartApi, SalesChartsState } from '../../@types/filters';
import { AreaChartFiltersState } from '../../@types/filterBars';

const initialState: SalesChartsState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceId: 0,
    shopId: 0,
    countryId: 0,
    profit: 0,
    customerId: 0
  },
  loading: false,
  isLoaded: false,
  error: false,
  chartApi: {
    chartData: [],
    chartOptions: { labels: [] },
    acosData: [],
    acosPercent: 0,
    acosTotal: 0,
    budgetData: [],
    budgetPercent: 0,
    budgetTotal: 0,
    salesData: [],
    salesPercent: 0,
    salesTotal: 0,
    loaded: false,
    margeData: [],
    margePercent: 0,
    margeTotal: 0,
    expendituresData: [],
    expendituresPercent: 0,
    expendituresTotal: 0
  }
};

const slice = createSlice({
  name: 'chartApi',
  initialState,
  reducers: {
    setChartApi(state, action) {
      state.chartApi = action.payload;
    },
    setFilters(state, action: PayloadAction<AreaChartFiltersState>) {
      state.filters = action.payload;
    },
    getChartsApi: (state) => {
      state.loading = true;
    },
    getChartsApiSuccess: (state, action: PayloadAction<ChartApi>) => {
      state.loading = false;
      state.isLoaded = true;
      state.error = false;
      state.chartApi = action.payload;
    },
    getChartsApiError: (state) => {
      state.error = true;
      state.loading = false;
    }
  }
});

export function fetchChartsApi(filters: AreaChartFiltersState) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    let fullQuery = `/rest/v1/shopChartData`;
    fullQuery = `${fullQuery}/filter/${filters.timePeriod.startFilter}/${filters.timePeriod.endFilter}`;
    fullQuery = `${fullQuery}/countryid/${filters.countryId}`;
    fullQuery = `${fullQuery}/shopid/${filters.shopId}`;
    fullQuery = `${fullQuery}/sourceid/${filters.sourceId}`;
    dispatch(getChartsApi());
    try {
      // console.log(fullQuery);
      const response = await axiosInstance.get(fullQuery);
      dispatch(getChartsApiSuccess(response.data));
    } catch (error) {
      dispatch(getChartsApiError());
    }
  };
}

/* export function getChartApi({
  startFilter,
  endFilter,
  countryId,
  shopId
}: any) {
  return async () => {
    if (shopId === 0) return {};
    const { dispatch } = store;
    dispatch(slice.actions.setChartApi(initialState.chartApi));
    const filterQuery = `/filter/${startFilter}/${endFilter}`;
    const countryIdQuery = `/countryid/${countryId}`;
    const shopIdQuery = `/shopid/${shopId}`;

    const response = await axios.get(
      `/rest/v1/shopChartData${filterQuery}${countryIdQuery}${shopIdQuery}`
    );
    dispatch(slice.actions.setChartApi(response.data));
    return response.data;
  };
} */

// Reducer
export default slice.reducer;

// Actions
export const {
  setChartApi,
  setFilters,
  getChartsApi,
  getChartsApiSuccess,
  getChartsApiError
} = slice.actions;
