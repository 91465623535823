// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';
const ROOTS_AUTH = '/auth';
const ROOTS_MAINTENANCE = '/maintenance';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginCustomer: path(ROOTS_AUTH, '/login/:customerid?'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_HOME = {
  components: '/components',
  dashboard: ROOTS_DASHBOARD
};

export const PATH_MAINTENANCE = {
  root: ROOTS_MAINTENANCE
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  account: path(ROOTS_DASHBOARD, '/user/account')
};
