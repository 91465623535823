import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  ThemeProvider,
  createMuiTheme,
  experimentalStyled as styled
} from '@material-ui/core/styles';
import { Box, Card, Container, Hidden, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Page from '../components/Page';
import { FullLogo } from '../components/Logo';
import { useAxiosContext } from '../context/AxiosProvider';
// import './fonts/IBMPlexMono/IBMPlexMono.ttf';

const loginTitleFont = createMuiTheme({
  typography: {
    fontFamily: ['IBM Plex Mono', 'monospace'].join(',')
  }
});

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 960,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Maintenance() {
  const { t: translate } = useTranslation(['login', 'component']);
  const { isMaintenanceMode } = useAxiosContext();
  const history = useHistory();
  const srcImage = '/static/illustrations/Vector-11.svg';

  useEffect(() => {
    if (!isMaintenanceMode) {
      history.push('/');
    }
  }, [isMaintenanceMode, history]);

  return !isMaintenanceMode ? null : (
    <RootStyle title={`${translate('component:maintenance')}`}>
      <HeaderStyle>
        <RouterLink to="/">
          <FullLogo />
        </RouterLink>
      </HeaderStyle>

      <Hidden mdDown>
        <SectionStyle>
          <Box sx={{ position: 'relative' }}>
            <ThemeProvider theme={loginTitleFont}>
              <Typography
                variant="body1"
                fontWeight={700}
                fontSize="1.5rem"
                sx={{
                  px: 5,
                  mt: 15,
                  mb: 15,
                  pl: 4.5,
                  pr: 4.5,
                  fontFamily: 'IBM Plex Mono, monospace',
                  position: 'absolute',
                  left: 0,
                  top: 2.5
                }}
              >
                Business Intelligence & Marketing Suite
              </Typography>
            </ThemeProvider>
            <Box sx={{ padding: '2rem .5rem', opacity: 0.1 }}>
              <img src={srcImage} alt="Login image" />
            </Box>
          </Box>
        </SectionStyle>
      </Hidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Box
            sx={{
              mb: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Typography variant="h2" gutterBottom>
                {translate('component:maintenance_title')}
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: 'text.secondary' }}
                paddingTop="36px"
              >
                {translate('component:maintenance_msg_1')}
              </Typography>
              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                {translate('component:maintenance_msg_2')}
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: 'text.secondary' }}
                paddingTop="24px"
              >
                {translate('component:maintenance_msg_3')}
              </Typography>
            </Box>
          </Box>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
