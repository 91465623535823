import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../utils/axiosConfig';
import {
  EdiTradingPartnerExchangeOverviewState,
  EdiTradingPartnerExchangeOverviewResponse
} from '../../../@types/acs';
import { APIStatus, APIError } from '../../../@types/APIStatus';
import { SortBy, TableSkipLimit } from '../../../@types/filters';

const initialState: EdiTradingPartnerExchangeOverviewState = {
  params: '',
  APIStatus: APIStatus.IDLE,
  response: {
    count: 0,
    rows: [],
    total: {
      tradingPartnerID: 0,
      from: 0,
      to: 0,
      name: '',
      percentageTotal: 0,
      total: 0
    }
  }
};

const slice = createSlice({
  name: 'ediTradingPartnerExchangeOverview',
  initialState,
  reducers: {
    setUrlParams(
      state: EdiTradingPartnerExchangeOverviewState,
      action: PayloadAction<string>
    ) {
      state.params = action.payload;
    },
    setEdiTradingPartnerExchangeOverview(
      state: EdiTradingPartnerExchangeOverviewState,
      action: PayloadAction<EdiTradingPartnerExchangeOverviewResponse>
    ) {
      state.response = action.payload;
    },
    getEdiTradingPartnerExchangeOverview: (
      state: EdiTradingPartnerExchangeOverviewState
    ) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getEdiTradingPartnerExchangeOverviewSuccess: (
      state: EdiTradingPartnerExchangeOverviewState,
      action: PayloadAction<EdiTradingPartnerExchangeOverviewResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getEdiTradingPartnerExchangeOverviewError: (
      state: EdiTradingPartnerExchangeOverviewState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchEdiTradingPartnerExchangeOverview(
  abortController: AbortController,
  params: Record<string, any>,
  { by, order }: SortBy,
  { skip, limit }: TableSkipLimit
) {
  return async (dispatch: any) => {
    try {
      dispatch(getEdiTradingPartnerExchangeOverview());
      const response = await axiosInstance.get(
        '/api/v2/acs/ediTradingPartnerExchangeOverview',
        {
          signal: abortController.signal,
          params: { ...params, order, by, skip, limit }
        }
      );
      dispatch(getEdiTradingPartnerExchangeOverviewSuccess(response.data));
    } catch (error) {
      dispatch(getEdiTradingPartnerExchangeOverviewError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setUrlParams,
  getEdiTradingPartnerExchangeOverview,
  getEdiTradingPartnerExchangeOverviewSuccess,
  getEdiTradingPartnerExchangeOverviewError
} = slice.actions;
