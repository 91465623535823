import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
import { axiosInstance } from '../../utils/axiosConfig';

// ----------------------------------------------------------------------

const initialState = {
  config: {}
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfigAction(state, action) {
      state.config = action.payload;
    }
  }
});

export function setConfig() {
  return async () => {
    const { dispatch } = store;
    const response = await axiosInstance.get(`/api/v1/config.json`);

    dispatch(slice.actions.setConfigAction(response.data));
  };
}

// Reducer
export default slice.reducer;

// Actions
export const { setConfigAction } = slice.actions;
