import { useEffect, useState } from 'react';
import { AutocompleteChangeReason } from '@material-ui/core';
import AutocompleteMulti from '../../general/form/AutocompleteMulti';
import { MultiselectItem } from '../../../@types/filters';
import { areListsEqual } from '../../../utils/helper';

type Props = {
  disabled?: boolean;
  label: string;
  placeholder: string;
  valueSelected: Array<MultiselectItem>;
  options: Array<MultiselectItem>;
  isAdminField?: boolean;
  onChange: (value: Array<MultiselectItem>) => void;
};

function MultiselectDefault({
  label,
  placeholder,
  options,
  valueSelected,
  onChange,
  isAdminField = true,
  disabled = false
}: Props) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<Array<MultiselectItem>>([]);

  useEffect(() => setSelection(valueSelected), [valueSelected]);

  useEffect(() => {
    if (!isOpen && !areListsEqual(valueSelected, selection)) {
      onChange(selection);
    }
  }, [isOpen]);

  const handleChange = (
    ev: React.SyntheticEvent<Element, Event>,
    selected: Array<MultiselectItem>,
    reason: AutocompleteChangeReason
  ) => {
    switch (reason) {
      case 'clear': {
        setSelection(selected);
        onChange(selected);
        break;
      }
      case 'remove-option':
      case 'select-option': {
        setSelection(selected);
        if (!isOpen) onChange(selected);
        break;
      }
    }
  };

  return (
    <AutocompleteMulti
      isAdminField={isAdminField}
      disabled={disabled}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      label={label}
      placeholder={placeholder}
      valueSelected={selection}
      options={options}
      onChange={(ev, value, reason) => handleChange(ev, value, reason)}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      getOptionSelected={(option, value) => option.id === value.id}
    />
  );
}

export default MultiselectDefault;
