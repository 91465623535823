import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from '../redux/rootReducer';
import {
  fetchFilterValues,
  fetchCampaigns,
  fetchCampaignTypes,
  fetchCampaignStatus,
  fetchAdGroups,
  fetchOnlineMarketingRanges,
  fetchProductListRanges,
  fetchSourceChannels,
  fetchCustomers,
  fetchMandantShops,
  fetchOrderStatus,
  fetchCategories,
  fetchSuppliers,
  fetchManufactures,
  fetchCountries,
  fetchWarehouses,
  fetchStockManagementTimePeriods,
  fetchArticleAttributes,
  fetchOrderListRanges,
  fetchAcsFilters
} from '../redux/slices/filters';
import {
  CountrySelectItem,
  CustomerSelectItem,
  MultiselectItem,
  ShopSelectItem,
  SourceSelectItem,
  StatusSelectItem,
  WarehouseSelectItem
} from '../@types/filters';
import useLocales from './useLocales';

// ----------------------------------------------------------------------

function useFilters() {
  const dispatch = useDispatch();
  const { response, APIStatus, error, filtersStatus } = useSelector(
    (state: RootState) => state.filters
  );
  const {
    currentLang: { value: lang }
  } = useLocales();

  const { t } = useTranslation(['filter', 'component']);

  const {
    countries,
    mandantShops,
    customers,
    sourceChannels,
    warehouses,
    orderStatus
  } = response;

  const shopList_old: Array<ShopSelectItem> = useMemo(
    () => mandantShops.map((s) => ({ shopId: s.id, shopName: s.name })),
    [mandantShops]
  );
  const warehouses_old: Array<WarehouseSelectItem> = useMemo(
    () =>
      warehouses.map((s) => ({
        warehouseId: s.id,
        warehouseName: s.name,
        warehouseExtId: 0
      })),
    [warehouses]
  );
  const customers_old: Array<CustomerSelectItem> = useMemo(
    () => customers.map((s) => ({ customerId: s.id, customerName: s.name })),
    [customers]
  );
  const countryOrders_old: Array<CountrySelectItem> = useMemo(
    () =>
      countries.map((s) => ({
        countryId: s.id,
        countryName: s.name,
        countryIso: ''
      })),
    [countries]
  );
  const sourcesList_old: Array<SourceSelectItem> = useMemo(
    () =>
      sourceChannels.map((s) => ({
        sourceId: s.id,
        sourceName: s.name,
        shopId: 0,
        shopName: '',
        countries: []
      })),
    [sourceChannels]
  );

  const abc = [
    { name: 'A', id: 1 },
    { name: 'B', id: 2 },
    { name: 'C', id: 3 }
  ];

  const searchType: Array<MultiselectItem> = useMemo(
    () => [
      { name: t('filter:full_text'), id: 1 },
      { name: t('filter:loose_text'), id: 2 },
      { name: t('filter:sku'), id: 3 },
      { name: t('filter:ean'), id: 4 },
      { name: t('filter:asin'), id: 5 },
      { name: t('filter:parent_sku'), id: 6 },
      { name: t('filter:variation_id'), id: 7 }
    ],
    [lang]
  );

  return {
    searchType,
    shopList_old,
    customers_old,
    countryOrders_old,
    sourcesList_old,
    warehouses_old,
    abc,
    response,
    APIStatus,
    error,
    filtersStatus,
    getFilterValues: () => dispatch(fetchFilterValues()),
    getCampaignOptions: () => dispatch(fetchCampaigns()),
    getCampaignTypesOptions: () => dispatch(fetchCampaignTypes()),
    getCampaignStatusOptions: () => dispatch(fetchCampaignStatus()),
    getAdGroups: () => dispatch(fetchAdGroups()),
    getOnlineMarketingRanges: () => dispatch(fetchOnlineMarketingRanges()),
    getProductListRanges: () => dispatch(fetchProductListRanges()),
    getOrderListRanges: () => dispatch(fetchOrderListRanges()),
    getCustomers: () => dispatch(fetchCustomers()),
    getMandantShops: () => dispatch(fetchMandantShops()),
    getCountries: () => dispatch(fetchCountries()),
    getCategories: () => dispatch(fetchCategories()),
    getSuppliers: () => dispatch(fetchSuppliers()),
    getOrderStatus: () => dispatch(fetchOrderStatus(lang)),
    getManufactures: () => dispatch(fetchManufactures()),
    getSourceChannels: () => dispatch(fetchSourceChannels()),
    getArticleAttributes: () => dispatch(fetchArticleAttributes()),
    getStockManagementTimePeriods: () =>
      dispatch(fetchStockManagementTimePeriods()),
    getWarehouses: () => dispatch(fetchWarehouses()),
    getAcsFilters: () => dispatch(fetchAcsFilters())
  };
}

export default useFilters;
