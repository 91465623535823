import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { Dispatch } from 'react';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { AreaChartFiltersState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import { Pagination, TopSellerState } from '../../@types/reduxStates';
import { ChartGraphData, TopSellerResponse } from '../../@types/responsesAPI';
import { TopSellerStateRow } from '../../@types/tableRows';
import { sortByProperty } from '../../utils/sortValues';
import { axiosInstance } from '../../utils/axiosConfig';

// ----------------------------------------------------------------------

const initialState: TopSellerState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceId: 0,
    shopId: 0,
    countryId: 0,
    customerId: 0,
    profit: 0
  },
  data: {
    rows: [],
    selectedRow: {
      labels: [],
      values: []
    },
    totalCount: 0,
    sources: [],
    topTen: [],
    summary: {
      expenses: 0,
      expensesPrev: 0,
      margin: 0,
      marginPrev: 0,
      marginTending: 0,
      orders: 0,
      revenue: 0,
      revenuePrev: 0,
      revenueTending: 0,
      stock: 0
    }
  },
  APIStatusSelected: APIStatus.IDLE,
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'topSeller',
  initialState,
  reducers: {
    setFilters: (
      state: TopSellerState,
      action: PayloadAction<AreaChartFiltersState>
    ) => {
      state.filters = action.payload;
    },
    getTopSeller: (state: TopSellerState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getTopSellerSuccess: (
      state: TopSellerState,
      action: PayloadAction<TopSellerResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.data = action.payload;
    },
    getTopSellerError: (
      state: TopSellerState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    },
    getSelectedRow: (state: TopSellerState) => {
      state.APIStatusSelected = APIStatus.PENDING;
      state.error = undefined;
    },
    getSelectedRowSuccess: (
      state: TopSellerState,
      action: PayloadAction<ChartGraphData>
    ) => {
      state.APIStatusSelected = APIStatus.FULFILLED;
      state.data.selectedRow = action.payload;
    },
    getSelectedRowError: (
      state: TopSellerState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatusSelected = APIStatus.REJECTED;
      state.error = action.payload;
    },
    getMoreRows: (state: TopSellerState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getMoreRowsSuccess: (
      state: TopSellerState,
      action: PayloadAction<Array<TopSellerStateRow>>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.data.rows = action.payload;
    },
    getMoreRowsError: (
      state: TopSellerState,
      action: PayloadAction<APIError>
    ) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    },
    sortTopSeller: (
      state: TopSellerState,
      action: PayloadAction<Array<TopSellerStateRow>>
    ) => {
      state.data.rows = action.payload;
    }
  }
});

export function fetchTopSeller(
  filters: AreaChartFiltersState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    try {
      dispatch(getTopSeller());
      const response = await axiosInstance.get('/api/v2/topSeller', {
        signal: abortController.signal,
        params: {
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter,
          countryId: filters.countryId > 0 ? filters.countryId : undefined,
          shopId: filters.shopId > 0 ? filters.shopId : undefined,
          sourceId: filters.sourceId > 0 ? filters.sourceId : undefined,
          customerId: filters.customerId > 0 ? filters.customerId : undefined,
          skip: skip === undefined ? 0 : skip,
          limit: limit === undefined ? 5 : limit,
          order: order === undefined ? 'desc' : order,
          by: by === undefined ? 'revenue' : by
        }
      });
      dispatch(getTopSellerSuccess(response.data));
    } catch (error) {
      dispatch(getTopSellerError(error as APIError));
    }
  };
}

export function fetchSelectedRow(
  filters: AreaChartFiltersState,
  articleId: string,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    try {
      dispatch(getSelectedRow());
      const response = await axiosInstance.get('/api/v2/productSources', {
        signal: abortController.signal,
        params: {
          articleId,
          from: filters.timePeriod.startFilter,
          till: filters.timePeriod.endFilter,
          countryId: filters.countryId > 0 ? filters.countryId : undefined,
          shopId: filters.shopId > 0 ? filters.shopId : undefined,
          sourceId: filters.sourceId > 0 ? filters.sourceId : undefined,
          customerId: filters.customerId > 0 ? filters.customerId : undefined
        }
      });
      dispatch(getSelectedRowSuccess(response.data));
    } catch (error) {
      dispatch(getSelectedRowError(error as APIError));
    }
  };
}

export function sortTopSellerBy(
  list: Array<TopSellerStateRow>,
  orderBy: string,
  order: 'asc' | 'desc'
) {
  const orderedList = sortByProperty(list, orderBy, order);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(sortTopSeller(orderedList));
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  getTopSeller,
  getTopSellerSuccess,
  getTopSellerError,
  getSelectedRow,
  getSelectedRowSuccess,
  getSelectedRowError,
  getMoreRows,
  getMoreRowsSuccess,
  getMoreRowsError,
  sortTopSeller
} = slice.actions;
