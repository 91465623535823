import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { EventEmitter } from 'events';

const eventEmitter = new EventEmitter();

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  timeout: 10000
});

interface ApiErrorResponse {
  type?: string;
  message: string;
}

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<ApiErrorResponse>) => {
    if (error.response) {
      const { status, data } = error.response;
      const isAuthPage = window.location.pathname.includes('/auth/');
      switch (status) {
        case 401:
        case 403:
          if (!isAuthPage) eventEmitter.emit('authError');
          break;
        case 500:
          if (data && data.type === 'service_unavailable') {
            eventEmitter.emit('maintenanceMode');
          }
          break;
        case 503:
          eventEmitter.emit('maintenanceMode');
          break;
        default:
          return Promise.reject(data || 'Something went wrong');
      }
      return Promise.reject(data || 'Something went wrong');
    }

    const errorDetails = {
      code: error.code || 'UNKNOWN_ERROR',
      message: error.message || 'Something went wrong'
    };

    switch (error.code) {
      case 'ECONNABORTED':
        errorDetails.message = 'Request timeout';
        break;
      case 'ERR_CANCELED':
        errorDetails.message = `Request canceled: ${error.config?.url}`;
        break;
    }

    console.error(`Axios error: ${error.message}`);
    return Promise.reject(errorDetails);
  }
);

export { axiosInstance, eventEmitter };
