import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { ProfitFilterState } from '../../@types/filterBars';
import { ProfitState } from '../../@types/reduxStates';
import { ProfitReponse } from '../../@types/responsesAPI';
import { axiosInstance } from '../../utils/axiosConfig';

const initialState: ProfitState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    profitBy: 0
  },
  response: {
    articleId: 0,
    positionTypeId: 0,
    unitsSold: 0,
    revenueNetto: 0,
    revenueBrutto: 0,
    creditPayout: 0,
    cancellations: 0,
    unitsReturn: 0,
    returns: 0,
    discount: 0,
    shippingCosts: 0,
    customs: 0,
    operatingCosts: 0,
    deliveryCosts: 0,
    storageCosts: 0,
    transportCosts: 0,
    productCosts: 0,
    advertisingCosts: 0,
    marketplaceFees: 0,
    netAfterCreditPayout: 0,
    bruttoAfterCreditPayout: 0,
    unitsSoldAfterReturns: 0,
    netAfterCancellations: 0,
    grossAfterCancellations: 0,
    returnsRate: 0,
    db1: 0,
    db1Rate: 0,
    db2: 0,
    db2Rate: 0,
    db3: 0,
    db3Rate: 0,
    revenueAfterCancellationsIncRetoure: 0,
    db1PerItem: 0,
    db2PerItem: 0,
    db3PerItem: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'profit',
  initialState,
  reducers: {
    setFilters: (state: any, action: PayloadAction<ProfitFilterState>) => {
      state.filters = action.payload;
    },
    getProfit: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getProfitSuccess: (state: any, action: PayloadAction<ProfitReponse>) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getProfitError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchProfit(filters: ProfitFilterState, articleId: string) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    dispatch(getProfit());
    try {
      const params = {
        idArticle: articleId,
        from: filters.timePeriod.startFilter,
        till: filters.timePeriod.endFilter,
        profitBy: filters.profitBy > 0 ? filters.profitBy : undefined
      };
      const response = await axiosInstance.get('/api/v2/article/profit', {
        params
      });
      dispatch(getProfitSuccess(response.data));
    } catch (error) {
      dispatch(getProfitError(error as APIError));
    }
  };
}

export const { setFilters, getProfit, getProfitError, getProfitSuccess } =
  slice.actions;

export default slice.reducer;
