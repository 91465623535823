import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { axiosInstance } from '../../utils/axiosConfig';

import { APIStatus, APIError } from '../../@types/APIStatus';

import { PricesChangesState } from '../../@types/reduxStates';
import { PriceChangesReponse } from '../../@types/responsesAPI';
import { AreaChartFiltersState } from '../../@types/filterBars';

const initialState: PricesChangesState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    sourceId: 0,
    shopId: 0,
    countryId: 0,
    profit: 0,
    customerId: 0
  },
  APIStatus: APIStatus.IDLE,
  data: {
    xaxis: [],
    stock: [],
    avPrice: [],
    revenue: []
  }
};

const slice = createSlice({
  name: 'PriceChanges',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<AreaChartFiltersState>) {
      state.filters = action.payload;
    },
    getPriceChanges: (state) => {
      state.APIStatus = APIStatus.PENDING;
    },
    getPriceChangesSuccess: (
      state,
      action: PayloadAction<PriceChangesReponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.data = action.payload;
    },
    getPriceChangesError: (state, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchPriceChanges(
  filters: AreaChartFiltersState,
  articleId: number = 0
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
    const params = {
      article: articleId > 0 ? articleId : undefined,
      from: filters.timePeriod.startFilter,
      till: filters.timePeriod.endFilter,
      shop: filters.shopId,
      source: filters.sourceId,
      country: filters.countryId,
      customer: filters.customerId > 0 ? filters.customerId : undefined
    };
    dispatch(getPriceChanges());
    try {
      const response = await axiosInstance.get('/api/v2/stockChart', {
        params
      });
      dispatch(getPriceChangesSuccess(response.data));
    } catch (error) {
      dispatch(getPriceChangesError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  getPriceChanges,
  getPriceChangesSuccess,
  getPriceChangesError
} = slice.actions;
