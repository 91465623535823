import React, { createContext, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { eventEmitter } from '../utils/axiosConfig';
import useAuth from '../hooks/useAuth';

interface AxiosContextProps {
  isMaintenanceMode: boolean;
}

const AxiosContext = createContext<AxiosContextProps | undefined>(undefined);

export const useAxiosContext = () => {
  const context = useContext(AxiosContext);
  if (!context) {
    throw new Error('useAxiosContext must be used inside AxiosProvider');
  }
  return context;
};

interface AxiosProviderProps {
  children: React.ReactNode;
}

const AxiosProvider: React.FC<AxiosProviderProps> = ({ children }) => {
  const history = useHistory();
  const { logout } = useAuth();
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useEffect(() => {
    const handleAuthError = () => logout();
    const handleMaintenance = () => {
      setIsMaintenanceMode(true);
      history.push('/maintenance');
    };

    eventEmitter.on('authError', handleAuthError);
    eventEmitter.on('maintenanceMode', handleMaintenance);

    return () => {
      eventEmitter.off('authError', handleAuthError);
      eventEmitter.off('maintenanceMode', handleMaintenance);
    };
  }, [history]);

  return (
    <AxiosContext.Provider value={{ isMaintenanceMode }}>
      {children}
    </AxiosContext.Provider>
  );
};

export default AxiosProvider;
